import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { BulletList } from '@conventic-web/internal.cvt.data-display.bullet-list';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

/*---Images---*/
import ImgSchlaglicht from '../../images/Pics/pic_kf_schlaglicht.svg';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const StrategieberatungPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />

      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />
      <BlockStandard
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
        subheadline={frontmatter.strategyConsultingMain.headline}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.strategyConsultingMain.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
      <BlockStandard
        bgImage={ImgSchlaglicht}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        zIndex={6}
        padding={PaddingObj.PaddingConsultingService}
        color={CvtColorSchema.white}
        subheadline={frontmatter.strategyConsultingServices.headline}
        textAlignment={'right'}
      >
        <BulletList
          list={frontmatter.strategyConsultingServices.list}
          colorFirst={CvtColorSchema.white}
          colorSecond={CvtColorSchema.white}
          alignBulletPoints={'right'}
        />
      </BlockStandard>
      <BlockStandard
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={5}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
        subheadline={frontmatter.strategyConsultingOffer.headline}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.strategyConsultingOffer.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
    </Layout>
  );
};

StrategieberatungPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default StrategieberatungPage;

export const strategieberatungPageQuery = graphql`
  query StrategieberatungPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        strategyConsultingMain {
          content
          headline
        }
        strategyConsultingServices {
          headline
          list {
            text
          }
        }
        strategyConsultingOffer {
          content
          headline
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageDescription
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
